



































import { Vue, Component } from 'vue-property-decorator'
import InputCommon from '@/components/common/InputCommon.vue'

@Component({
  components: { InputCommon },
})
export default class Login extends Vue {
  form = {
    login: '',
    password: '',
  }

  handleUserLogin() {
    this.$emit('login', this.form)
  }
}
