















import { Vue, Component } from 'vue-property-decorator'
import LoginForm from '@/components/forms/LoginForm.vue'

@Component({
  components: { LoginForm },
})
export default class Login extends Vue {
  get isAuth() {
    return this.$store.getters['user/authorized']
  }

  async auth(data: object): Promise<void> {
    await this.$store.dispatch('user/login', data)

    if (this.isAuth) {
      this.$router.push({ name: 'Statistic' })
    }
  }
}
